import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import cookies from 'vue-cookies'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
      },
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          meta: { title: 'Dashboard' },
          component: () => import('./views/course'),
        },
        {
          path: '/notification',
          name: 'Notification',
          meta: { title: 'Notification' },
          component: () => import('./views/notification'),
        },
        {
          path: '/profil',
          name: 'Profil Saya',
          meta: { title: 'Profil Saya' },
          component: () => import('./views/profile'),
        },
        {
          path: '/forgot-password',
          name: 'Forgot Password',
          meta: { title: 'Forgot Password' },
          component: () => import('./views/forgot-password'),
        },
        {
          path: '/program',
          name: 'Program',
          meta: { title: 'Program' },
          component: () => import('./views/program'),
        },
        {
          path: '/programType',
          name: 'Program Type',
          meta: { title: 'Program Type' },
          component: () => import('./views/programType'),
        },
        {
          path: '/mainProgram',
          name: 'Main Program',
          meta: { title: 'Main Program' },
          component: () => import('./views/program/mainProgram.vue'),
        },
        {
          path: '/program/persetujuan',
          name: 'Program Persetujuan',
          meta: { title: 'Program Persetujuan' },
          component: () => import('./views/program/proses/persetujuan.vue'),
        },
        {
          path: '/program/konfirmasi-dokter',
          name: 'Program Konfirmasi Dokter',
          meta: { title: 'Program Konfirmasi Dokter' },
          component: () => import('./views/program/proses/konfirmasiDokter.vue'),
        },
        {
          path: '/program/unggah-resep-dokter',
          name: 'Program Unggah Resep Dokter',
          meta: { title: 'Program Unggah Resep Dokter' },
          component: () => import('./views/program/proses/unggahResepDokter.vue'),
        },
        {
          path: '/program/hasil-tes-lab',
          name: 'Program Unggah Hasil Tes Lab',
          meta: { title: 'Program Unggah Hasil Tes Lab' },
          component: () => import('./views/program/proses/UnggahHasilTes/index.vue'),
        },
        {
          path: '/program/unggah-hasil-tes',
          name: 'Program Unggah Hasil Tes',
          meta: { title: 'Program Unggah Hasil Tes' },
          component: () => import('./views/program/proses/UnggahHasilTes/unggahTes.vue'),
        },
        {
          path: '/program/tes-list',
          name: 'Program Tes List',
          meta: { title: 'Program Tes List' },
          component: () => import('./views/program/proses/UnggahHasilTes/tesList.vue'),
        },
        {
          path: '/survey',
          name: 'Survey',
          meta: { title: 'Survey' },
          component: () => import('./views/program/proses/survey.vue'),
        },
        {
          path: '/unggah-asuransi',
          name: 'Unggah Asuransi',
          meta: { title: 'Unggah Asuransi' },
          component: () => import('./views/program/proses/unggahAsuransi.vue'),
        },
        {
          path: '/scan',
          name: 'Main Scanner',
          meta: { title: 'Main Scanner' },
          component: () => import('./views/scanner/index.vue'),
        },
        {
          path: '/scan/unggah-bukti',
          name: 'Scanner Upload',
          meta: { title: 'Scanner Upload' },
          component: () => import('./views/scanner/unggahBukti.vue'),
        },
        {
          path: '/scheduling',
          name: 'Scheduling',
          meta: { title: 'Scheduling' },
          component: () => import('./views/scheduling/index.vue'),
        },
        {
          path: '/history',
          name: 'History',
          meta: { title: 'History' },
          component: () => import('./views/scheduling/history.vue'),
        },
        {
          path: '/extending',
          name: 'Extending',
          meta: { title: 'Extending' },
          component: () => import('./views/program/extending/extend.vue'),
        },
        {
          path: '/extending/stop',
          name: 'Extending Stop',
          meta: { title: 'Extending Stop' },
          component: () => import('./views/program/extending/stop.vue'),
        },
        {
          path: '/extending/success',
          name: 'Extending Success',
          meta: { title: 'Extending Success' },
          component: () => import('./views/program/extending/success.vue'),
        },

        {
          path: '/article',
          name: 'Artikel List',
          meta: { title: 'Artikel List' },
          component: () => import('./views/article'),
        },
        {
          path: '/article/:id',
          name: 'Artikel Detail',
          meta: { title: 'Artikel Detail' },
          component: () => import('./views/article/detail.vue'),
        },
        {
          path: '/bantuan',
          name: 'Bantuan',
          meta: { title: 'Bantuan' },
          component: () => import('./views/bantuan'),
        },
        {
          path: '/video-article',
          name: 'Video Artikel',
          meta: { title: 'Video Artikel' },
          component: () => import('./views/video'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      meta: { authRequired: false },
      name: 'auth',
      children: [
        {
          path: 'login',
          name: 'masuk',
          meta: {
            title: 'Masuk',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: 'register',
          name: 'register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: 'forgot-password',
          name: 'Lupa Password',
          meta: {
            title: 'Lupa Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: 'lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    {
      path: '/404',
      name: 'route404',
      meta: {
        title: 'Error 404',
      },
      component: () => import('./views/auth/404'),
    },
    {
      path: '/500',
      meta: {
        title: 'Error 500',
      },
      component: () => import('./views/auth/500'),
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  //   NProgress.start()
  //   setTimeout(() => {
  //     NProgress.done()
  //   }, 300)
  const storedUserData = localStorage.getItem('userData')
  const storedAccessToken = localStorage.getItem('accessToken')
  const storedRole = localStorage.getItem('role')
  const userData = JSON.parse(storedUserData)
  // const accessToken = JSON.parse(storedAccessToken)
  const role = JSON.parse(storedRole)
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!storedAccessToken) {
      // console.log('nasuk 1')
      next({
        name: 'masuk',
      })
    } else {
      // console.log('masuk 2')
      next()
    }
  } else {
    next()
  }
})

export default router
