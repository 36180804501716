<template>
  <div>
    <!-- Tidak ada elemen visual, hanya memantau state dari store -->
  </div>
</template>

<script>
import { computed, onMounted, watch, ref, watchEffect, onUnmounted } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    const visible = computed(() => store.state.notificationDesktop.visible)
    const currentTime = ref(new Date())

    watchEffect(() => {
      if (visible.value) {
        store.dispatch('notificationDesktop/showNotification')
      }
    })

    // watch untuk memeriksa waktu setiap interval tertentu
    watch(
      currentTime,
      () => {
        const jadwals = JSON.parse(localStorage.getItem('jadwals')) || []
        const now = new Date()
        const formattedTime = `${now.getHours()}:${String(now.getMinutes()).padStart(2, '0')}`
        const endDate = new Date() // Set endDate sebagai hari ini
        console.log('jalan')
        jadwals.forEach(jadwal => {
          console.log('formattedTime' + formattedTime, 'jadwalJam: ' + jadwal.jam)
          if (jadwal.jam === formattedTime && now <= endDate) {
            if (!jadwal.isAlarm) {
              store.dispatch('notificationDesktop/updateNotificationContent', {
                title: 'Alarm Jadwal',
                message: `Alarm untuk jadwal pada jam ${formattedTime}`,
              })
              store.dispatch('notificationDesktop/updateVisibility', true)
              jadwal.isAlarm = true
            }
          }
        })

        const stringifiedCards = JSON.stringify(jadwals)
        // console.log('stringifiedcars', stringifiedCards)
        localStorage.setItem('jadwals', stringifiedCards)
      },
      { immediate: true },
    )

    onMounted(() => {
      store.dispatch('notificationDesktop/checkPermission')

      // Memulai interval untuk mengupdate currentTime setiap detik
      const intervalId = setInterval(() => {
        currentTime.value = new Date()
      }, 10000) // Cek setiap 10 detik
      console.log(intervalId)
      // Hapus interval ketika komponen dihancurkan
      onUnmounted(() => {
        clearInterval(intervalId)
      })
    })

    return {
      visible,
    }
  },
}
</script>
