import { createStore } from 'vuex'
import user from './user'
import settings from './settings'
import course from './course'
import category from './category'
import vendor from './vendor'
import banner from './banner'
import akun from './akun'
import transaction from './transaction'
import discountedCourse from './discountedCourse'
import admin from './admin'
import { notificationDesktop } from './notificationDesktop'

export default createStore({
  modules: {
    user,
    settings,
    course,
    category,
    vendor,
    banner,
    akun,
    transaction,
    discountedCourse,
    admin,
    notificationDesktop,
  },
  state: {
    program: '',
    programType: '',
    testLabTypeId: '',
    dataHasilTes: {
      // persetujuan: '1',
      // unggahHasilTes: '2',
      // konfirmasiDokter: '3',
      // unggahResepDokter: '4',
      // verifikasi: '5',
      // qrCode: '',
      persetujuan: '',
      unggahHasilTes: '',
      konfirmasiDokter: '',
      unggahResepDokter: '',
      verifikasi: '',
      qrCode: '',
    },
    bannerStep: 0,
    isRedemption: false,
    isRedemptionExist: false,
    isJadwalExist: false,
    programId: '',
    isAurora: false,
    isExtendingExist: false,
    programName: '',
  },
  mutations: {
    updateAttribute(state, { attribute, value }) {
      state.dataHasilTes[attribute] = value
    },
    mutateBannerStep(state, payload) {
      state.bannerStep = payload
    },
    mutateRedemptionStatus(state, payload) {
      state.isRedemption = payload
    },
    mutateRedemptionStatusExist(state, payload) {
      state.isRedemptionExist = payload
    },
    mutateJadwalExist(state, payload) {
      state.isJadwalExist = payload
    },
    mutateisAurora(state, payload) {
      state.isAurora = payload
    },
    mutateProgram(state, payload) {
      state.program = payload
    },
    mutateProgramType(state, payload) {
      state.programType = payload
    },
    mutateTestLabTypeId(state, payload) {
      state.testLabTypeId = payload
    },
    mutateProgramId(state, payload) {
      state.programId = payload
    },
    mutateProgramName(state, payload) {
      state.programName = payload
    },
    mutateIsExtendingExist(state, payload) {
      state.isExtendingExist = payload
    },
  },
  actions: {
    updateProgram({ commit }, payload) {
      commit('mutateProgram', payload)
    },
    updateProgramType({ commit }, payload) {
      commit('mutateProgramType', payload)
    },
    updateAttribute({ commit }, payload) {
      commit('updateAttribute', payload)
    },
    changeBannerStep({ commit }, payload) {
      commit('mutateBannerStep', payload)
    },
    changeRedemptionStatus({ commit }, payload) {
      commit('mutateRedemptionStatus', payload)
    },
    changeRedemptionStatusExist({ commit }, payload) {
      commit('mutateRedemptionStatusExist', payload)
    },
    changeIsAuroraStatus({ commit }, payload) {
      commit('mutateisAurora', payload)
    },
    updateTestLabTypeId({ commit }, payload) {
      commit('mutateTestLabTypeId', payload)
    },
    updateProgramId({ commit }, payload) {
      commit('mutateProgramId', payload)
    },
    updateProgramName({ commit }, payload) {
      commit('mutateProgramName', payload)
    },
    updateIsExtendingExist({ commit }, payload) {
      commit('mutateIsExtendingExist', payload)
    },
    updateJadwalExist({ commit }, payload) {
      commit('mutateJadwalExist', payload)
    },
  },
  getters: {},
})
