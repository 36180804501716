<template>
  <div :class="$style.topbar">
    <div
      style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    "
      class="container pt-3 px-5 px-sm-4"
    >
      <img
        src="../../assets/img/logo-pulih.png"
        alt=""
        style="width: 126px;height: 60px;cursor: pointer;"
        @click="handleDashboard"
      />
      <vb-user-menu />
    </div>
  </div>
</template>

<script>
// import VbSearch from './Search'
import VbUserMenu from './UserMenu'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
export default {
  components: {
    VbUserMenu,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])
    const router = useRouter()
    const handleDashboard = () => {
      router.push('/dashboard')
    }
    return {
      user,
      handleDashboard,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
