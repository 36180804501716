export const notificationDesktop = {
  namespaced: true,
  state: {
    hasPermission: false, // Status izin notifikasi
    visible: false, // Kontrol visibilitas notifikasi
    title: 'Notifikasi Default',
    message: 'Ini adalah pesan notifikasi.',
  },
  mutations: {
    SET_PERMISSION(state, permission) {
      state.hasPermission = permission
    },
    SET_VISIBLE(state, visibility) {
      state.visible = visibility
    },
    SET_NOTIFICATION_CONTENT(state, { title, message }) {
      state.title = title
      state.message = message
    },
  },
  actions: {
    checkPermission({ commit }) {
      if (!('Notification' in window)) {
        alert('Browser ini tidak mendukung notifikasi desktop')
      } else if (Notification.permission === 'granted') {
        commit('SET_PERMISSION', true)
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          commit('SET_PERMISSION', permission === 'granted')
        })
      }
    },
    showNotification({ state, commit }) {
      if (state.hasPermission && state.visible) {
        new Notification(state.title, { body: state.message })
        commit('SET_VISIBLE', false) // Reset visibility setelah notifikasi muncul
      }
    },
    updateVisibility({ commit }, visibility) {
      commit('SET_VISIBLE', visibility)
    },
    updateNotificationContent({ commit }, { title, message }) {
      commit('SET_NOTIFICATION_CONTENT', { title, message })
    },
  },
}
