<template>
  <a-layout class="vb__layout">
    <a-layout-content>
      <div
        :class="{
          [$style.container]: true,
          vb__layout__squaredBorders: settings.isSquaredBorders,
          vb__layout__cardsShadow: settings.isCardShadow,
          vb__layout__borderless: settings.isBorderless,
          [$style.gray]: settings.authPagesColor === 'gray',
          ['w-100']: true,
        }"
        class="justify-content-center"
      >
        <!-- <div class="w-100 text-center"> -->
        <!-- <img width="100" src="../../assets/img/logo_nodecenter.png"/> -->
        <!-- </div> -->

        <router-view v-slot="{ Component }">
          <transition :name="settings.routerAnimation" mode="out-in" class="h-100 w-100">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuthLayout',
  computed: mapState(['settings']),
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
