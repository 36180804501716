<template>
  <div :class="$style['footer']">
    <div
      style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;"
      class="container py-5 "
    >
      <!-- INI KIRI -->
      <div style="height: 100%; width: 20%;">
        <div>
          <img
            src="../../assets/img/logo-pulih.png"
            alt=""
            srcset=""
            style="height: 84px; height: 60px;"
          />
          <p class="m-0 mt-4 text-white">Term of Use</p>
          <p class="m-0 mt-1 text-white">Privacy Policy</p>
          <p class="m-0 mt-1 text-white">FAQ</p>
        </div>
      </div>
      <!-- INI KANAN -->
      <div
        class="d-flex flex-column align-items-center justify-content-center mr-3 mr-lg-0"
        style="height: 100%; width: 20%;"
      >
        <div>
          <p class="m-0 my-3 text-white ">Available on</p>
          <a
            href="https://play.google.com/store/apps/details?id=com.astrazeneca.pulih&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="../../assets/img/google-play.png"
              alt=""
              srcset=""
              :class="$style['img-google-play']"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center pb-3">
      <p class="m-0 text-white" style="font-size: 10px;">©Copyright 2023 PULIH INDONESIA</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
