import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'
import cookies from 'vue-cookies'
import router from '../../router'

// export const baseURL = 'https://brivice.digitala.id:3000/api'
// export const baseURL = 'https://api.modc.id'
export const baseURL = 'https://pulih-api-prod.digitala.id'
// export const baseURL = 'https://pulih-api-prod.digitala.id/staging'
// export const baseURL = 'http://localhost:3001'

const apiClient = axios.create({
  // baseURL: `${process.env.VUE_APP_BACKEND_URL}api`,
  baseURL,
  // baseURL: 'http://localhost:3000/api',
  // timeout: 1000,
  withCredentials: true,
  headers: {
    // 'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': 'https://pulih-api-prod.digitala.id',
    // 'Access-Control-Allow-Headers': 'accept',
  },
  // headers: {
  //   Accept: 'application/json',
  //   'Content-Type': 'application/json',
  // },
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  console.log(response, 'inierror')

  if (response.data) {
    if (response.data.status) {
      const title = response.data.status.split('_').join(' ')
      notification.warning({
        message: title,
        description: response.data.message || 'There must be some error. Please try again later.',
      })

      if (response.data.status === 'SESSION_TIMEOUT') {
        cookies.remove('sessionId')
        return router.push('/masuk')
      }
    } else if (response.data.error) {
      notification.error({
        message: 'Error',
        description:
          response.data.errorList[0] || 'There must be some error. Please try again later.',
      })
    }
  } else {
    notification.warning({
      message: 'Internal Server Error',
      description: 'There must be some error. Please try again later.',
    })
  }
})

export default apiClient
