import router from '@/router'
import { notification } from 'ant-design-vue'
import apiClient from '@/services/axios'
import VueCookies from 'vue-cookies'

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    role: '',
    email: '',
    avatar: '',
    authorized: false,
    loading: false,
    accountFetchIsTouched: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async LOGIN({ commit, dispatch, rootState }, { email, password }) {
      try {
        const { data } = await apiClient.post('/auth/login', {
          email,
          password,
          role: 'patient',
        })
        console.log(data, '<<<<<STORE USER')
        const dataUser = {
          user: {
            id: data.id,
            role: data.role,
            email: data.email,
            name: data.fullname,
            accessToken: data.token,
            code: data.code,
          },
          sessionId: data.token,
        }
        localStorage.setItem('userData', JSON.stringify(dataUser))
        localStorage.setItem('accessToken', JSON.stringify(dataUser.user.accessToken))
        return new Promise((resolve, reject) => {
          resolve({ user: dataUser.user, session: data.token })
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async REGISTER({ commit, dispatch, rootState }, { payload }) {
      try {
        console.log(payload, 'inipayloadnya')
        const { data } = await apiClient.post('/patient', payload)
        console.log(data, '<<<<<STORE REGISTER')
        // const dataUser = {
        //   user: {
        //     id: data.id,
        //     role: data.role,
        //     email: data.email,
        //     name: data.fullname,
        //     accessToken: data.token,
        //     code: data.code,
        //   },
        //   sessionId: data.token,
        // }
        // localStorage.setItem('userData', JSON.stringify(dataUser))
        // localStorage.setItem('accessToken', JSON.stringify(dataUser.user.accessToken))
        // return new Promise((resolve, reject) => {
        //   resolve({ user: dataUser.user, session: data.token })
        // })
        if (data.data) {
          router.push('/auth/login')
          notification.success({
            message: 'Berhasil Register',
            description: 'Data Pasien Berhasil di Submit',
          })
        } else {
          notification.error({
            message: 'Gagal Register',
            description: 'Ulangi proses register',
          })
        }
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async LOAD_CURRENT_ACCOUNT({ commit, dispatch, rootState }, { token }) {
      try {
        const outerData = await apiClient.get('/users/load?accesstoken=' + token)
        if (!outerData) router.push('/masuk')
        console.log(outerData, 'outerData')
        const user = outerData.data.userData
        console.log(user, 'user')
        commit('SET_STATE', {
          name: user.name,
          email: user.email,
          sessionId: token,
          id: user.id,
          role: user.role,
        })
        VueCookies.set(
          'user',
          { id: user.id, email: user.email, name: 'Admin', role: 'admin' },
          null,
          '/',
        )
        // return new Promise((resolve, reject) => {
        //   // resolve({})
        // })
      } catch (err) {
        // router.push('/masuk')
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async LOGOUT({ commit, rootState }) {
      try {
        // await apiClient.post('/account/logout')
        VueCookies.remove('sessionId')
        VueCookies.remove('user')
        localStorage.clear()
        commit('SET_STATE', { sessionId: null })
        router.push({ name: 'masuk' })
      } catch (err) {
        console.log(err)
      }
    },
  },
  getters: {
    user: state => state,
  },
}
